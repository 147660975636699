import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme, width="inherit", ...props }) => ({
  background: props.backgroundColor ?? theme.palette.primary.main,
  borderRadius: 16,
  border: "0.2rem solid",
  padding: '12px 23px',
  color: 'white',
  width: width,
  fontWeight: 600,
  fontSize: 16,
  textAlign: "center",
  textTransform: 'capitalize',
  '&:hover': {
    border: `0.2rem solid ${(props.backgroundColor ?? theme.palette.primary.main)}`,
    backgroundColor: (props.backgroundColor ?? theme.palette.primary.main) + "22",
    color: props.backgroundColor ?? theme.palette.primary.main,
  },
}));

const OutlinedButton = styled(Button)(({ theme, width="inherit", ...props }) => ({
  borderRadius: 16,
  border: "0.2rem solid",
  borderColor: props.backgroundColor ?? theme.palette.primary.main,
  padding: '12px 23px',
  color: props.backgroundColor ?? theme.palette.primary.main,
  width: width,
  fontWeight: 600,
  fontSize: 16,
  textAlign: "center",
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: (props.backgroundColor ?? theme.palette.primary.main) + "22",
    color: props.backgroundColor ?? theme.palette.primary.main,
  },
}));



const InstaButton = ({ children, hidden=false, outlined=false, ...props }) => {
  if (hidden) {
    return <></>
  }
  return outlined ? (
    <OutlinedButton {...props}>
      {children}
    </OutlinedButton>
  ) : (
    <StyledButton {...props}>
      {children}
    </StyledButton>
  );
};

export default InstaButton;