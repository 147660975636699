import React from 'react';
import { Box, Divider, Grid, Link, } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{paddingTop: "3rem"}}>
    <Divider />
    <Box pt={4}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Grid
            container
            xs={12}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <Box
              sx={{
                padding: 0,
                margin: 0,
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              {/* <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/blog"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  Blog
                </Link>
              </Box>
              <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/faq"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  FAQ
                </Link>
              </Box>
              <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/privacy"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  Privacy
                </Link>
              </Box>
              <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/terms-of-service"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  Terms of Service
                </Link>
              </Box> */}
              <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/contact-us"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  Contact Us
                </Link>
              </Box>
              <Box sx={{ padding: {xs: 1, md: 0 }}}>
                <Link
                  href="/blog"
                  underline="hover"
                  color="inherit"
                  mx={2}
                >
                  Blog
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              textAlign: { xs: "center", md: "right" },
              paddingTop: { xs: 4, md: 0 },
            }}
          >
            © 2023 InstaPM, all rights reserved
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
  );
};

export default Footer;
