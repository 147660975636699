import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactUs = () => {
  return (
    <Container sx={{flex: "1 0 auto"}} maxWidth="sm">
      <Box py={5}>
        <Typography variant="h4" gutterBottom>Contact Us</Typography>
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          If you have any questions, feel free to reach out to us:
        </Typography>
        <List>
          {/* <ListItem>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText>
              109 Nessus Street
              <br />
              Vaughan, ON L4L 5K6
              <br />
              Canada
            </ListItemText>
          </ListItem> */}
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="tel:416-347-3556">(416)-347-3556</a>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="mailto:info@instapm.ca">info@instapm.ca</a>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default ContactUs;
