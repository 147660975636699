import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const data = {
    title: "FAQ",
    rows: [
        {
            title: "What software do I need to download in order to use InstaPM?",
            content: `There's no need to download software to use InstaPM as it is completely web-based and can be accessed through a browser.`,
        },
        {
            title: "Does InstaPM support collaborative authoring?",
            content:
                `Yes, InstaPM supports collaborative authoring, allowing multiple users within a company to have access to the same product monographs without having to email documents back and forth.
                This can help streamline the review and approval process and ensure that all stakeholders have access to the most up-to-date information.`,
        },
        {
            title: "Do you offer personalized client support?",
            content: `At InstaPM, we understand the importance of personalized client support. Our team is dedicated to providing continuous, high-quality support to our clients. We offer personalized support over email, ensuring that any questions, concerns, or issues are addressed in a timely and efficient manner.`,
        },
        // {
        //     title: "Is the data securely stored?",
        //     content: `At InstaPM, we use industry-standard AES-256 encryption to secure our database, which is hosted on a cloud platform that meets strict security standards such as SOC 2 and ISO 27001. We also implement SSL/TLS encryption for all data in transit, ensuring that data is protected as it moves between our servers and your device.`,
        // },
        {
            title: "Do you support US SPL?",
            content: `We are constantly working on improving InstaPM and adding new features to better serve our users. Our team is currently developing the capability to support US SPL and we are committed to delivering this feature as soon as possible. We understand the importance of being able to upload and work with US SPLs and we appreciate your patience as we work towards making this a reality. Please stay tuned for updates on this feature and let us know if you have any other questions or suggestions.`,
        },
        {
            title: "Who are the creators of InstaPM?",
            content: `We are a team of regulatory professionals and engineers with over a decade of experience. Our team is committed to delivering the highest quality service and support to our clients, ensuring that they have access to the most up-to-date information and resources to succeed.`,
        },
        {
            title: "I'm interested, where can I learn more?",
            content: (
                <div>
                    <p>
                        For more information about InstaPM, please email us at info@instapm.ca. We would be happy to answer any questions you have and provide you with more information about our product and services.
                    </p>
                    <p>
                        Stay connected with us on social media for the latest updates, news, and insights:
                    </p>
                    <ul>
                        <li>
                            LinkedIn:{" "}
                            <a href="https://www.linkedin.com/company/instapm" target="_blank" rel="noopener noreferrer">
                                LinkedIn Page
                            </a>
                        </li>
                        <li>
                            YouTube:{" "}
                            <a href="https://www.youtube.com/watch?v=tTUNVZ4Vh8U" target="_blank" rel="noopener noreferrer">
                                Demo
                            </a>
                        </li>
                    </ul>
                    <p>
                        Visit our <a href="https://instapm.ca/blog" target="_blank" rel="noopener noreferrer"> blog</a> for more informative articles:{" "}

                    </p>
                </div>
            ),
        },
        // {
        //     title: "What is the package version",
        //     content: <p>current version is 1.2.1</p>,
        // },
    ],
};

const styles = {
    bgColor: "transparent", // Set a transparent background color
    titleTextColor: "#333", // Change the title text color to a darker shade
    rowTitleColor: "#333", // Change the row title color to a darker shade
    rowContentColor: "#666", // Change the row content color to a medium shade
    arrowColor: "blue", // Change the arrow color to blue
    rowContentFontSize: "14px", // Reduce the font size of the row content
    rowContentPaddingLeft: "30px", // Add some padding to the left of row content
    rowContentPaddingRight: "30px", // Add some padding to the right of row content
    rowContentPaddingTop: "10px", // Add some padding to the top of row content
    rowContentPaddingBottom: "10px", // Add some padding to the bottom of row content
    rowContentLineHeight: "1.5", // Increase the line height of row content
    arrowIconFontSize: "12px", // Reduce the font size of the arrow icon
    arrowIconSpacingRight: "5px", // Add some spacing to the right of the arrow icon
};

const config = {
    // animate: true,
    // tabFocus: true
};

const FaqBlock = () => {

    return (
        <div>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}

export default FaqBlock;