import React, {useState} from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Container,
  CircularProgress,
} from "@mui/material";
import { green, red } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InstaButton from "./reusables/InstaButton";
import heroImage from "./assets/images/instapm_hero.png";
import axios from "axios"

export default function Home() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(false)

  const hero = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <img
              src={heroImage}
              alt="medicine"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const formSubmit = async () => {

    setLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const body = {
        name,
        email,
        company,
    }
    
    try {
      await axios.post("https://api.instapm.ca/api/auth/subscribe/", body, config)
      setTimeout(() => {
        setLoading(false)
        setFormSubmitted(true)
        setTimeout(() => {
          setFormSubmitted(false)
        }, 3000)
      }, 2000)
    } catch (exception) {
      setTimeout(() => {
        setLoading(false)
        setFormError(true)
        setTimeout(() => {
          setFormError(false)
        }, 3000)
      }, 2000)
    } finally {
      setName("")
      setEmail("")
      setCompany("")
    }
  }

  const form = () => {

    if(loading) {
      return (
        <Grid container alignItems="center" spacing={2} py={10} style={{height:"100%"}}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <CircularProgress/>
            </Box>
          </Grid>
        </Grid>
      )
    }

    if (formSubmitted) {
      return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} style={{height:"100%"}}>
          <Grid item>
            <CheckCircleIcon sx={{ color: green[500] }} />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Form submitted successfully!
            </Typography>
          </Grid>
        </Grid>
      )
    }

    if (formError) {
      return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} style={{height:"100%"}}>
          <Grid item>
            <ErrorIcon sx={{ color: red[500] }} />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Something went wrong! Please try again.
            </Typography>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container alignItems="center" spacing={2} py={{ xs: 0, md: 10 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            type="name"
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="name"
            label="Company Name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InstaButton onClick={() => {formSubmit()}} variant="contained" color="primary" type="submit" width="100%">
            Submit
          </InstaButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Typography variant="h2">
                Join Our Mailing List
              </Typography>
            </Box>
            <Box mt={3} textAlign={"center"}>
              <Typography variant="h4">
                Those who join will be entered to win 1 of 3 Amazon Gift Cards
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>{hero()}</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box height="100%">
              {form()}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
