import React from 'react';
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material';
import InstaButton from "./reusables/InstaButton";
import logo from "./assets/images/InstaPm.png";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = () => {
  let location = useLocation();
  const isMobile = useMediaQuery(theme => theme?.breakpoints.down('sm'));

  const demoButtonText = isMobile ? "Try InstaPM" : "Start An Interactive Demo"
  const demoButtonRightMargin = isMobile ? 0 : 2

  const isEventPage = location.pathname === "/event"
  return (
    <Grid container>
      <Grid xs={3} item>
        <IconButton component={!isEventPage ? Link : null} to="/" disableRipple={true}>
          <img src={logo} width={"150rem"} alt="button icon" />
        </IconButton>
      </Grid>
      {!isEventPage && (
        <Grid item xs={9} sx={{display: "flex", justifyContent: 'flex-end'}}>
          <Box mr={demoButtonRightMargin}>
            <InstaButton outlined={!isMobile} href="https://app.instapm.ca/demo">{demoButtonText}</InstaButton>
          </Box>
          <Box hidden={isMobile}>
            <InstaButton href="https://app.instapm.ca/login">Login</InstaButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
