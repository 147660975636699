import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import Home from './Home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ContactUs from './ContactUs';
import Header from './Header';
import Footer from './Footer';
import MediumBlogs from './MediumBlogs';
import BlogPost from './BlogPost'
import Event from './Event'
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Container maxWidth="lg" sx={{paddingTop: 3, paddingBottom: 3}}>
          <Header/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<MediumBlogs />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/event" element={<Event />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer/>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
