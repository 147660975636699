import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, CardMedia } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

const MediumBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchMediumBlogs();
  }, []);

  const fetchMediumBlogs = async () => {
    const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@instapmsoftware');
    const data = await response.json();
    setBlogs(data.items);
  };

  return (
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant='h3' >Blog</Typography>
      </Box>
      <Grid container spacing={3}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={blog.thumbnail}
                  alt={blog.title}
                />
                <CardContent>
                  <Box>
                    <Typography gutterBottom variant="h6">
                      <Link to={`/blog/${blog.guid.split('/').pop()}`}>
                        {blog.title}
                      </Link>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {moment(blog.pubDate).format('MMM DD, YYYY')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MediumBlogs;
