import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BlogPost = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetchBlogBySlug(slug);
  }, [slug]);

  const fetchBlogBySlug = async (slug) => {
    const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@instapmsoftware');
    const data = await response.json();
    const foundBlog = data.items.find((item) => item.guid.split('/').pop() === slug);
    setBlog(foundBlog);
  };

  return (
    <div>
      {blog ? (
        <>
          <h1>{blog.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        </>
      ) : (
        <h2>Loading...</h2>
      )}
      <style jsx>{`
        img {
          max-width: 100%;
          height: auto;
        }
      `}</style>
    </div>
  );
};

export default BlogPost;
