import React from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import facebook from "./assets/images/facebook.svg";
import InstaButton from "./reusables/InstaButton";
import heroImage from "./assets/images/instapm_hero.png";
import exportFeature from "./assets/images/feature1.png";
import validationFeature from "./assets/images/feature2.png";
import diffFeature from "./assets/images/feature3.png";
import { Avatar } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import CodeIcon from "@mui/icons-material/Code";
import FaqBlock from "./Faq";

export default function Home() {

  const hero = () => {
    return (
      <Grid container alignItems="center" spacing={2} py={10}>
        <Grid item xs={12} sm={4}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{ fontWeight: "bold" }}
          >
            The modern product monograph platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <img
              src={heroImage}
              alt="medicine"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const qualityTiles = () => {
    return (
      <Box py={5} sx={{ backgroundColor: "#F8F8F8", borderRadius: "25px" }}>
        <Box display="flex" justifyContent="center">
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ marginBottom: { xs: "24px", md: "0" } }}
            >
              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop="24px"
                px={2}
                sx={{ flex: 1 }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#4CAF50",
                    width: "80px",
                    height: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <ElectricBoltIcon sx={{ fontSize: "3rem" }} />
                </Avatar>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Faster PM Creation
                </Typography>
                <Typography variant="subtitle1">
                  With our platform, you can create PMs faster through our
                  guided tooling and exporting systems
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ marginBottom: { xs: "24px", md: "0" } }}
            >
              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop="24px"
                px={2}
                sx={{ flex: 1 }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#2196F3",
                    width: "80px",
                    height: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <CodeIcon sx={{ fontSize: "3rem" }} />
                </Avatar>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  XML Support
                </Typography>
                <Typography variant="subtitle1">
                  We support generation of your PM in XML format using Health Canada's
                  latest guidelines
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ marginBottom: { xs: "24px", md: "0" } }}
            >
              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop="24px"
                px={2}
                sx={{ flex: 1 }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#FFC107",
                    width: "80px",
                    height: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <CloudIcon sx={{ fontSize: "3rem" }} />
                </Avatar>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Work in the cloud
                </Typography>
                <Typography variant="subtitle1">
                  All content is securely stored and regularly backed up in the
                  cloud hosted by datacenters on Canadian soil
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const callToAction = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        py={7}
        sx={{ backgroundColor: "#F8F8F8", borderRadius: "25px" }}
      >
        <Box pb={5}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Ready to get started?
          </Typography>
        </Box>
        <Box>
          <InstaButton href="https://app.instapm.ca/demo">
            Start An Interactive Demo
          </InstaButton>
        </Box>
      </Box>
    );
  };

  const featureSectionHeader = () => {
    return (
      <Box display="flex" justifyContent="center" mb={5}>
        <Typography variant="h1" sx={{ fontWeight: "bold" }}>
          Meet InstaPM
        </Typography>
      </Box>
    );
  };

  const features = () => {
    return (
      <Box>
        {/* Feature 1 */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", md: "flex-start" }}
          mb={10}
        >
          <Box
            width={{ xs: "100%", md: "50%" }}
            mb={{ xs: 5, md: 0 }}
            pl={{ xs: 0, md: 5 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              Export to multiple formats
            </Typography>
            <Typography variant="body1">
              Use InstaPM's smart-export feature to turn your Product
              monograph into different file formats such as DOCX (Microsoft Word), XML and HTML
              within seconds
            </Typography>
          </Box>
          <Box textAlign="center" width={{ xs: "100%", md: "50%" }}>
            <img src={exportFeature} alt="placeholder" height="250" />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", md: "row-reverse" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", md: "flex-start" }}
          mb={10}
        >
          <Box
            width={{ xs: "100%", md: "50%" }}
            mb={{ xs: 5, md: 0 }}
            pl={{ xs: 0, md: 5 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              Health Canada XML Validation
            </Typography>
            <Typography variant="body1">
              Our platform uses the latest Health Canada XML guidelines to
              automatically validate the structure of generated XMLs before you
              submit your PMs to Health Canada
            </Typography>
          </Box>
          <Box textAlign="center" width={{ xs: "100%", md: "50%" }}>
            <img src={validationFeature} alt="placeholder" height="250" />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "center", md: "flex-start" }}
          mb={10}
        >
          <Box
            width={{ xs: "100%", md: "50%" }}
            mb={{ xs: 5, md: 0 }}
            pl={{ xs: 0, md: 5 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              Change Management
            </Typography>
            <Typography variant="body1">
              With our platform you can easily revert to an older version of
              your PM and track progress throughout the document lifecycle.
            </Typography>
          </Box>
          <Box textAlign="center" width={{ xs: "100%", md: "50%" }}>
            <img src={diffFeature} alt="placeholder" height="250" />
          </Box>
        </Box>
      </Box>
    );
  };

  const madeByCompanyList = () => {
    return (
      <Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography
              variant="body1"
              color="textSecondary"
              textAlign="center"
              sx={{ marginBottom: { xs: "24px", md: "0" } }}
            >
              Made with developers from
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ marginBottom: { xs: "24px", md: "0" } }}
          >
            <Box display="flex" justifyContent="center">
              <object data={facebook} height="48">
                {" "}
              </object>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ marginBottom: { xs: "24px", md: "0" } }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2560px-Microsoft_logo_%282012%29.svg.png"
                alt="Microsoft Logo"
                height="40"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ marginBottom: { xs: "24px", md: "0" } }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Indeed_logo.svg/2560px-Indeed_logo.svg.png"
                alt="Indeed Logo"
                height="40"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            sx={{ marginBottom: { xs: "24px", md: "0" } }}
          >
            <Box display="flex" justifyContent="center">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/0/0c/MIT_logo.svg"
                alt="MIT Logo"
                height="40"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Container maxWidth="lg">
        <Box>{hero()}</Box>
        <Box>{qualityTiles()}</Box>
        <Box mt={10}>{featureSectionHeader()}</Box>
        <Box>{features()}</Box>
        <Box my={10}><FaqBlock/></Box>
        <Box mb={2}>{madeByCompanyList()}</Box>
        <Box>{callToAction()}</Box>
      
      </Container>
    </Box>
  );
}
